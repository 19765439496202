import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import EventService from '../../services/eventService';
import UserService from '../../services/userService';
import { EventDTO, Event } from '../../types/Event';
import theme from '../../theme';
import { Chip, IconButton, Typography, FormControlLabel, Checkbox, MenuItem, Switch, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import FullScreenLoader from '../loader/FullScreenLoader';
import { MapContainer, TileLayer } from 'react-leaflet';
import RoomIcon from '@mui/icons-material/Room';

// Configure dayjs plugins
dayjs.extend(utc);

type EventFormProps = {
    event?: Event | null;
    loading: boolean
}

const EventForm: React.FC<EventFormProps> = ({ event, loading }) => {
    const libraries: ('places')[] = ['places'];
    const [title, setTitle] = useState('');
    const [customTitle, setCustomTitle] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState<Dayjs | null>(dayjs().set('minute', 0).set('second', 0).set('millisecond', 0));
    const [keywords, setKeywords] = useState<string[]>(['']);
    const [error, setError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [hostId, setHostId] = useState('');
    const [isAutocompleteUsed, setIsAutocompleteUsed] = useState(false);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringDay, setRecurringDay] = useState<number | null>(null);
    const [needsAdminAttention, setNeedsAdminAttention] = useState(false);
    const [description, setDescription] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [seoKeywords, setSeoKeywords] = useState<string[]>(event?.seoKeywords || []);
    const [isRegeneratingKeywords, setIsRegeneratingKeywords] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);

    const navigate = useNavigate();
    const eventService = EventService();
    const userService = UserService();

    useEffect(() => {
        if (event) {
            setTitle(event.title || '');
            setCustomTitle(event.customTitle || '');
            setLocation(event.location);
            setDate(dayjs(event.date).utc());
            setKeywords(event.keywords || []);
            setIsAutocompleteUsed(true);
            setLatitude(event.latitude);
            setLongitude(event.longitude);
            setIsRecurring(event.recurringDay !== null);
            setRecurringDay(event.recurringDay);
            setNeedsAdminAttention(event.needsAdminAttention || false);
            setDescription(event.description || '');
            setSeoDescription(event.seoDescription || '');
            setSeoKeywords(event.seoKeywords || []);
        }
    }, [event]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: libraries
    });

    useEffect(() => {
        if (loadError) {
            console.error('Error loading Google Maps API:', loadError);
        }
    }, [loadError]);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await userService.getMe();
            if (user) {
                setHostId(user.id);
            }
        };
        fetchUser();
    }, []);

    const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
    }, []);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
    };

    const addKeyword = () => {
        setKeywords([...keywords, '']);
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
    };

    const onPlaceChanged = () => {
        setLocationError('');
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setLocation(place.formatted_address || '');
                setLatitude(lat);
                setLongitude(lng);
                setIsAutocompleteUsed(true);
            } else {
                setLocation(place.name || '');
                setLatitude(null);
                setLongitude(null);
                setIsAutocompleteUsed(false);
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!location || !date || !hostId) {
            setError('Vul alle verplichte velden in');
            return;
        }
        if (!isAutocompleteUsed) {
            setError('Gebruik een geldig adres voor de locatie.');
            return;
        }

        const eventData: EventDTO = {
            title,
            customTitle,
            location,
            date: date ? date.utc().format() : '',
            host: { id: hostId },
            keywords: keywords.filter(keyword => keyword.trim() !== ''),
            latitude,
            longitude,
            recurringDay: recurringDay,
            needsAdminAttention,
            seoDescription,
            seoKeywords: seoKeywords,
        };

        try {
            if (event) {
                await eventService.updateEvent(event.id, eventData);
            } else {
                await eventService.createEvent(eventData);
            }
            navigate('/events');
        } catch (error) {
            console.error('Failed to save event:', error);
            setError('Failed to save event. Please try again.');
        }
    };

    const adminAttentionToggle = (
        <FormControlLabel
            control={
                <Switch
                    checked={needsAdminAttention}
                    onChange={(e) => setNeedsAdminAttention(e.target.checked)}
                    color="warning"
                />
            }
            label="Needs Admin Attention"
            sx={{
                width: '100%',
                marginTop: 2
            }}
        />
    );

    const handleGenerateSeoDescription = async () => {
        try {
            setIsRegenerating(true);
            if (!event?.id) {
                const eventData: Partial<Event> = {
                    title,
                    customTitle,
                    location,
                    keywords: keywords.filter(keyword => keyword.trim() !== ''),
                };

                const newDescription = await eventService.generateSeoDescriptionDraft(eventData);
                if (newDescription) {
                    setSeoDescription(newDescription);
                }
            } else {
                const newDescription = await eventService.regenerateSeoDescription(event.id);
                if (newDescription) {
                    setSeoDescription(newDescription);
                }
            }
        } catch (error) {
            console.error('Failed to regenerate SEO description:', error);
        } finally {
            setIsRegenerating(false);
        }
    };

    const handleGenerateSeoKeywords = async () => {
        try {
            setIsRegeneratingKeywords(true);
            if (!event?.id) {
                const eventData: Partial<Event> = {
                    title,
                    customTitle,
                    location,
                    keywords: keywords.filter(keyword => keyword.trim() !== ''),
                };
                const newKeywords = await eventService.generateSeoKeywordsDraft(eventData);
                if (newKeywords) {
                    setSeoKeywords(newKeywords);
                }
            } else {
                const newKeywords = await eventService.regenerateSeoKeywords(event.id);
                if (newKeywords) {
                    setSeoKeywords(newKeywords);
                }
            }
        } catch (error) {
            console.error('Failed to regenerate SEO keywords:', error);
        } finally {
            setIsRegeneratingKeywords(false);
        }
    };


    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'center',
                width: '100%',
                minHeight: '100vh'
            }
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '@media (min-width: 768px)': {
                    width: '600px',
                },
            }}>
                {loading && <FullScreenLoader />}
                {error && <Typography color="error">{error}</Typography>}

                <Box sx={{ padding: 2, margin: 2, backgroundColor: 'background.paper', marginTop: 0 }}>
                    <Box sx={{ marginBottom: 4 }}>
                        <TextField
                            fullWidth
                            label="Titel"
                            value={customTitle}
                            onChange={(e) => setCustomTitle(e.target.value)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontFamily: 'Pricedown',
                                    fontSize: customTitle.length > 15 ? '18pt' : '32pt',
                                    lineHeight: '1.0',
                                    WebkitTextStroke: '1px black',
                                    textAlign: 'center',
                                }
                            }}
                        />
                    </Box>

                    {isLoaded && !loadError && (
                        <Box sx={{ height: '150px', marginBottom: 2, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
                            <MapContainer
                                center={latitude && longitude ? [latitude, longitude] : [52.1326, 5.2913]}
                                zoom={latitude && longitude ? 15 : 5}
                                style={{ height: '100%', width: '100%' }}
                                zoomControl={false}
                                scrollWheelZoom={false}
                                dragging={false}
                                touchZoom={false}
                                doubleClickZoom={false}
                                boxZoom={false}
                                keyboard={false}
                                attributionControl={false}
                            >
                                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
                                {latitude && longitude && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <RoomIcon />
                                    </IconButton>
                                )}
                            </MapContainer>
                        </Box>
                    )}
                    <Box sx={{ marginBottom: 4 }}>
                        <TextField
                            fullWidth
                            label="Ondertitel"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginY: 3 }}>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                            <Typography sx={{ marginRight: 2 }}>📅</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            format="DD/MM/YYYY HH:mm"
                                            ampm={false}
                                            label={isRecurring ? 'Startdatum' : 'Datum'}
                                            value={date}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const utcDate = newValue.utc(true);
                                                    setDate(utcDate);
                                                } else {
                                                    setDate(null);
                                                }
                                            }}
                                            timezone="UTC"
                                            closeOnSelect
                                            minutesStep={15}
                                            sx={{ width: '100%' }}
                                        />
                                    </LocalizationProvider>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isRecurring}
                                                onChange={(e) => {
                                                    setIsRecurring(e.target.checked);
                                                    if (!e.target.checked) {
                                                        setRecurringDay(null);
                                                    }
                                                }}
                                            />
                                        }
                                        label="Wekelijks"
                                        sx={{ ml: 2 }}
                                    />
                                </Box>
                                {isRecurring && (
                                    <TextField
                                        select
                                        fullWidth
                                        label="Dag van de week"
                                        value={recurringDay !== null ? recurringDay : ''}
                                        onChange={(e) => setRecurringDay(Number(e.target.value))}
                                        sx={{ mb: 2 }}
                                    >
                                        <MenuItem value={0}>Zondag</MenuItem>
                                        <MenuItem value={1}>Maandag</MenuItem>
                                        <MenuItem value={2}>Dinsdag</MenuItem>
                                        <MenuItem value={3}>Woensdag</MenuItem>
                                        <MenuItem value={4}>Donderdag</MenuItem>
                                        <MenuItem value={5}>Vrijdag</MenuItem>
                                        <MenuItem value={6}>Zaterdag</MenuItem>
                                    </TextField>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" width="100%">
                            <Typography sx={{ marginRight: 2 }}>📍</Typography>
                            <div style={{ width: '100%' }}>
                                {isLoaded && !loadError ? (
                                    <Autocomplete
                                        onLoad={onLoad}
                                        onPlaceChanged={onPlaceChanged}
                                        options={{
                                            types: ['address'],
                                            componentRestrictions: { country: 'nl' },
                                            fields: ['address_components', 'formatted_address', 'name', 'geometry'],
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Locatie"
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </Autocomplete>
                                ) : (
                                    <TextField
                                        fullWidth
                                        label="Locatie"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        disabled={!isLoaded}
                                    />
                                )}
                            </div>
                        </Box>
                    </Box>

                    {event?.description && (
                        <Box sx={{ marginY: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Originele Beschrijving
                            </Typography>
                            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography
                                    variant="body1"
                                    fontSize="13pt"
                                    sx={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: showFullDescription ? 'unset' : 3,
                                        WebkitBoxOrient: 'vertical',
                                        transition: 'all 0.3s ease',
                                        position: 'relative',
                                        mb: 1,
                                    }}
                                >
                                    {description}
                                </Typography>
                                {!showFullDescription && description.length > 200 && (
                                    <Box
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: '100px',
                                            background: `linear-gradient(to bottom, 
                                                transparent 0%, 
                                                ${theme.palette.background.paper} 66%
                                            )`,
                                            pointerEvents: 'none',
                                            marginTop: '-100px',
                                        })}
                                    />
                                )}
                                {description.length > 200 && (
                                    <Button
                                        onClick={() => setShowFullDescription(!showFullDescription)}
                                        variant='outlined'
                                        size='small'
                                        color='secondary'
                                        sx={{
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            width: '120px',
                                        }}
                                    >
                                        {showFullDescription ? 'Toon minder' : 'Toon meer'}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    )}

                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            SEO Beschrijving
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="SEO Beschrijving"
                                value={seoDescription}
                                onChange={(e) => setSeoDescription(e.target.value)}
                                sx={{ mb: 1 }}
                            />
                            <Button
                                onClick={handleGenerateSeoDescription}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                disabled={isRegenerating}
                                sx={{
                                    ml: 'auto',
                                    textAlign: 'center',
                                    width: '180px',
                                }}
                            >
                                {isRegenerating ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    event?.id ? 'Regenereer' : 'Genereer'
                                )}
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Tags
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                {keywords.map((keyword, index) => (
                                    <Chip
                                        key={index}
                                        label={
                                            <TextField
                                                value={keyword}
                                                onChange={(e) => handleKeywordChange(index, e.target.value)}
                                                variant="standard"
                                                InputProps={{ disableUnderline: true }}
                                                style={{ width: '100%', padding: '0 4px' }}
                                            />
                                        }
                                        onDelete={() => removeKeyword(index)}
                                        style={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            margin: '2px',
                                            maxWidth: '100px',
                                        }}
                                    />
                                ))}
                                <IconButton onClick={addKeyword} color="primary" size="small">
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
                                SEO Keywords
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {seoKeywords.map((keyword, index) => (
                                    <Chip
                                        key={index}
                                        label={keyword}
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Button
                                onClick={handleGenerateSeoKeywords}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                disabled={isRegeneratingKeywords}
                                sx={{
                                    marginLeft: 'auto',
                                    marginTop: '1rem',
                                    textAlign: 'center',
                                    width: '180px',
                                    display: 'block'
                                }}
                            >
                                {isRegeneratingKeywords ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    event?.id ? 'Regenereer' : 'Genereer'
                                )}
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ marginY: 3 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={needsAdminAttention}
                                    onChange={(e) => setNeedsAdminAttention(e.target.checked)}
                                    color="warning"
                                />
                            }
                            label="Needs Admin Attention"
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Box>

                    <Box sx={{ marginY: 3, display: 'flex', width: '100%' }}>
                        <Button
                            onClick={(e) => handleSubmit(e as any)}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={loading}
                            // sx={{ width: '200px' }}
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                event?.id ? 'Bijwerken' : 'Opslaan'
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
};

export default EventForm;
