import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

interface CreateMeetButtonProps {
    isAdmin: boolean;
}

const CreateMeetButton: React.FC<CreateMeetButtonProps> = ({ isAdmin }) => {
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    return (
        <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate('/create')}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 999,
            }}
        >
            <AddIcon />
        </Fab>
    );
};

export default CreateMeetButton;
