import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import UserService from '../services/userService';

const VerifyEmailSent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const stytch = useStytch();
    const [email, setEmail] = useState(location.state?.email || '');
    const [message, setMessage] = useState(location.state?.message || '');
    const userService = UserService();

    useEffect(() => {
        const getUserEmail = async () => {
            const user = await userService.getMe();
            if (user) {
                setEmail(user.email);
            }
        };
        getUserEmail();
    }, []);

    const handleResendEmail = async () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
        const verifyUrl = new URL('/verify-email', baseUrl).toString();
        try {
            await stytch.magicLinks.email.send(
                email,
                {
                    login_magic_link_url: verifyUrl,
                    signup_magic_link_url: verifyUrl,
                    login_expiration_minutes: 60,
                    signup_expiration_minutes: 60
                }
            );
            setMessage('Nieuwe verificatie e-mail verzonden. Controleer je inbox.');
        } catch (error) {
            console.error('Error resending verification email:', error);
            setMessage('Er ging iets mis. Probeer het opnieuw.');
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#111418',
            padding: 3
        }}>
            <Box sx={{
                backgroundColor: 'background.paper',
                padding: 3,
                borderRadius: 2,
                maxWidth: 400,
                width: '100%'
            }}>
                <Typography variant="h5" gutterBottom align="center">
                    Verifieer je e-mail
                </Typography>
                <Typography align="center" sx={{ mb: 3 }}>
                    {message || `We hebben een verificatie link naar ${email} verzonden. Controleer je e-mail om je account te verifiëren.`}
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleResendEmail}
                    sx={{ mb: 2 }}
                >
                    Nieuwe verificatie e-mail verzenden
                </Button>
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate('/')}
                >
                    Terug
                </Button>
            </Box>
        </Box>
    );
};

export default VerifyEmailSent; 