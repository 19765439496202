import { Box, Button, Card, CardActions, CardHeader, Typography } from '@mui/material';
import { useState } from 'react';
import { useStytch } from '@stytch/react';

interface VerifyEmailCardProps {
    email: string;
}

const VerifyEmailCard = ({ email }: VerifyEmailCardProps) => {
    const stytch = useStytch();
    const [message, setMessage] = useState<string>('Je hebt een mail ontvangen met een verificatielink. Na het activeren kan je gebruik maken van alle functies.');
    const [emailResent, setEmailResent] = useState<boolean>(false);

    const handleResendEmail = async () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
        const verifyUrl = new URL('/verify-email', baseUrl).toString();
        try {
            await stytch.magicLinks.email.send(
                email,
                {
                    login_magic_link_url: verifyUrl,
                    signup_magic_link_url: verifyUrl,
                    login_expiration_minutes: 60,
                    signup_expiration_minutes: 60
                }
            );
            setMessage('Nieuwe verificatie e-mail verzonden. Controleer je inbox.');
            setEmailResent(true);
        } catch (error) {
            console.error('Error resending verification email:', error);
            setMessage('Er ging iets mis. Probeer het opnieuw.');
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                '&:active': {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            <Box sx={{ minWidth: '250px', width: '100%', position: 'relative' }}>
                <CardHeader
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 1,
                    }}

                    title={
                        <Typography sx={{ fontWeight: 'bold', fontSize: '14pt' }} component="span">
                            Activeer je account!
                        </Typography>
                    }
                    subheader={
                        <Box>
                            <Box display='flex' minHeight='45px'>
                                <Typography variant='body2' color='text.secondary'>{message}</Typography>
                            </Box>
                        </Box>
                    }
                />
                <CardActions disableSpacing sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                    <Button variant='contained' color='primary' endIcon={<Typography sx={{ fontSize: '12pt' }}>✉️</Typography>} onClick={handleResendEmail} disabled={emailResent}>
                        <Typography>Verstuur opnieuw</Typography>
                    </Button>
                </CardActions>
            </Box>
        </Card >
    );
};

export default VerifyEmailCard;