import React, { useEffect, useState } from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Winactie: React.FC = () => {
    const [content, setContent] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/winactie.html')
            .then(response => response.text())
            .then(html => setContent(html))
            .catch(error => console.error('Error loading terms and conditions:', error));
    }, []);

    return (
        <Box sx={{ position: 'relative', margin: 0 }}>
            <IconButton
                onClick={() => navigate(-1)}
            >
                <ArrowBackIcon />
            </IconButton>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </Box>
    );
};

export default Winactie; 