import { useStytch } from '@stytch/react';
import { Event, EventDTO } from '../types/Event';

const EventService = () => {
    const stytch = useStytch();

    const getEvents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: Event[] = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    };

    const createEvent = async (event: EventDTO) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify(event),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('There was a problem creating the event:', error);
            throw error;
        }
    };

    const favoriteEvent = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem adding favorite meet:', error);
            throw error;
        }
    };

    const getEvent = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('There was a problem fetching the event:', error);
            throw error;
        }
    };

    const updateEvent = async (id: string, event: EventDTO) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify(event),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('There was a problem updating the event:', error);
            throw error;
        }
    };

    const deleteEvent = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('There was a problem deleting the event:', error);
            throw error;
        }
    };

    const regenerateSeoDescription = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/seo/description`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.updatedMeets?.[0]?.seoDescription || null;
        } catch (error) {
            console.error('Failed to regenerate SEO description:', error);
            throw error;
        }
    };

    const regenerateSeoKeywords = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.updatedMeets?.[0]?.seoKeywords || null;
        } catch (error) {
            console.error('Failed to regenerate SEO keywords:', error);
            throw error;
        }
    };

    const attendEvent = async (meetId: string, eventDate: Date): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/attend/${meetId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ attendedAt: eventDate })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem attending the meet:', error);
            throw error;
        }
    };

    const unattendEvent = async (meetId: string, eventDate: Date): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/unattend/${meetId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ eventDate })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem unattending the meet:', error);
            throw error;
        }
    };

    const generateSeoDescriptionDraft = async (meetData: Partial<Event>) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/seo/description/preview`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetData })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Failed to generate SEO description draft:', error);
            throw error;
        }
    };

    const generateSeoKeywordsDraft = async (meetData: Partial<Event>) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords/preview`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetData })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Failed to generate SEO keywords draft:', error);
            throw error;
        }
    };

    return {
        getEvents,
        createEvent,
        favoriteEvent,
        getEvent,
        updateEvent,
        deleteEvent,
        regenerateSeoDescription,
        regenerateSeoKeywords,
        attendEvent,
        unattendEvent,
        generateSeoDescriptionDraft,
        generateSeoKeywordsDraft,
    };
};

export default EventService;