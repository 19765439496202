import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Chip, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Event } from '../types/Event';
import EventService from '../services/eventService';
import UserService from '../services/userService';
import { User } from '../types/User';
import { useStytchUser } from '@stytch/react';
import FullScreenLoader from '../components/loader/FullScreenLoader';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import RoomIcon from '@mui/icons-material/Room';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import DirectionsIcon from '@mui/icons-material/Directions';
import { formatInTimeZone } from 'date-fns-tz';
import AuthModal from '../components/auth-modal/AuthModal';
import EventSchema from '../components/schema/EventSchema';
import ReviewSection from '../components/review/ReviewSection';
import RatingDisplay from '../components/rating/Rating';
import AttendeesCounter from '../components/attendees-counter/AttendeesCounter';
import AttendButton from '../components/attend-button/AttendButton';
import TopBar from '../components/top-bar/TopBar';
import { getNextOccurrence } from '../utils/date-utils';

const EventDetails = () => {
    const navigate = useNavigate();
    const { meetId } = useParams<{ meetId: string }>();
    const [event, setEvent] = useState<Event | null>(null);
    const [loading, setLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [animateFavorite, setAnimateFavorite] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const [user, setUser] = useState<User | null>(null);
    const eventService = EventService();
    const userService = UserService();
    const [isSharing, setIsSharing] = useState(false);
    const [attendeesCount, setAttendeesCount] = useState(event?.attendeesAmount || 0);
    const [isAttending, setIsAttending] = useState(meetId && user?.attended_meets.includes(meetId) || false);
    const [showFullDescription, setShowFullDescription] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (meetId) {
                try {
                    const [eventData, userData] = await Promise.all([
                        eventService.getEvent(meetId),
                        userService.getMe()
                    ]);
                    setEvent(eventData);
                    setAttendeesCount(eventData.attendeesAmount);
                    if (userData) {
                        setUser(userData);
                        const favorites = await userService.getFavoriteMeets();
                        setIsFavorite(favorites.includes(meetId));
                        setIsAttending(userData.attended_meets.includes(meetId));
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    navigate('/events');
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [meetId, stytchUser]);

    useEffect(() => {
        if (event) {
            setIsAttending(event && event.id && user?.attended_meets.includes(event.id) || false);
            setAttendeesCount(event.attendeesAmount);
        }
    }, [event, stytchUser]);

    const handleBack = () => {
        const params = new URLSearchParams(window.location.search);
        const urlView = params.get('view');
        const keywords = params.getAll('keywords');

        const searchParams = new URLSearchParams();
        if (urlView === 'map') {
            searchParams.set('view', 'map');
        }
        keywords.forEach(keyword => {
            searchParams.append('keywords', keyword);
        });

        navigate(`/events${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, {
            replace: true
        });
    };

    const handleFavorite = async () => {
        if (stytchUser && event) {
            setAnimateFavorite(true);
            setIsFavorite(!isFavorite);
            try {
                if (isFavorite) {
                    await userService.removeFavoriteMeet(event.id);
                } else {
                    await userService.addFavoriteMeet(event.id);
                }
            } catch (error) {
                console.error('Error updating favorite:', error);
            }
        } else {
            setShowAuthModal(true);
        }
    };
    const handleShare = () => {
        const newUrl = `/events/${event?.id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);
        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                url: `${window.location.origin}/events/${event?.id}`,
            })
                .then(() => {
                    setIsSharing(false);
                })
                .catch((error) => {
                    console.error('Error sharing:', error);
                    setIsSharing(false);
                });
        }
    };

    const handleOpenRoute = () => {
        if (event) {
            if (event.latitude !== null && event.longitude !== null) {
                window.open(`https://www.google.com/maps/dir/?api=1&destination=${event.latitude},${event.longitude}`, '_blank');
            } else {
                const encodedAddress = encodeURIComponent(event.location);
                window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`, '_blank');
            }
        }
    };

    const handleOpenLocation = () => {
        if (event && event.latitude !== null && event.longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`;
            window.open(mapsUrl, '_blank');
        } else if (event) {
            const encodedAddress = encodeURIComponent(event.location);
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };


    if (loading) {
        return <FullScreenLoader />;
    }

    if (!event) {
        return null;
    }

    const formattedDate = formatInTimeZone(new Date(event.date), 'Europe/London', 'd MMMM yyyy - HH:mm');

    const allKeywords = [...(event.keywords || []), ...(event.seoKeywords || [])];

    return (
        <>
            <EventSchema
                title={event.customTitle || event.title}
                description={event.seoDescription || event.description}
                startDate={event.date}
                location={event.location}
                latitude={event.latitude}
                longitude={event.longitude}
                keywords={event.keywords}
                recurringDay={event.recurringDay}
            />
            <Box display="flex" flexDirection="column" alignItems="center" sx={{
                '@media (min-width: 768px)': {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'center',
                    width: '100%',
                    minHeight: '100vh'
                }
            }} >
                <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%',
                    '@media (min-width: 768px)': {
                        width: '600px',
                    },
                }}>
                    <TopBar variant="details" onBack={handleBack} />
                    <Box sx={{ padding: 2, margin: 2, backgroundColor: 'background.paper', marginTop: 0 }}>
                        <Box sx={{ marginBottom: 4 }}>
                            <Typography
                                gutterBottom
                                sx={{
                                    fontFamily: 'Pricedown',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    WebkitTextStroke: '1px black',
                                    zIndex: 0,
                                    fontSize: '32pt',
                                    lineHeight: '1.0',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                {event.customTitle || event.title}
                            </Typography>
                        </Box>
                        {event.latitude !== null && event.longitude !== null && (
                            <Box
                                onClick={handleOpenLocation}
                                sx={{
                                    height: '150px',
                                    marginBottom: 2,
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                            >
                                <MapContainer
                                    center={[event.latitude, event.longitude]}
                                    zoom={15}
                                    style={{ height: '100%', width: '100%' }}
                                    zoomControl={false}
                                    scrollWheelZoom={false}
                                    dragging={false}
                                    touchZoom={false}
                                    doubleClickZoom={false}
                                    boxZoom={false}
                                    keyboard={false}
                                    attributionControl={false}
                                >
                                    <TileLayer
                                        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <RoomIcon />
                                    </IconButton>
                                    <Box sx={{ position: 'absolute', bottom: 8, right: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                        <RatingDisplay averageRating={event.averageRating} />
                                        <AttendeesCounter attendeeCount={attendeesCount} />
                                    </Box>
                                </MapContainer>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: 2 }}>
                            <Box>
                                <IconButton sx={{ height: '40px', width: '40px' }}
                                    onClick={handleFavorite}>
                                    {!isFavorite || !stytchUser ? (
                                        <BookmarkBorderIcon />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: '16pt',
                                                transform: 'rotate(135deg)',
                                                animation: animateFavorite ? 'twistBounce 1s' : 'none',
                                                '@keyframes twistBounce': {
                                                    '0%': { transform: 'scale(0) rotateY(0) rotate(135deg)' },
                                                    '50%': { transform: 'scale(1.5) rotateY(360deg) rotate(135deg)' },
                                                    '100%': { transform: 'scale(1) rotateY(360deg) rotate(135deg)' },
                                                },
                                            }}
                                        >
                                            🔖
                                        </Typography>
                                    )}
                                </IconButton>
                                <IconButton
                                    aria-label="share"
                                    onClick={handleShare}
                                    sx={{ height: '40px', width: '40px' }}
                                >
                                    {!isSharing ? (
                                        <IosShareIcon sx={{ fontSize: '20px' }} />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: '16pt',
                                                animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                                '@keyframes bounceShare': {
                                                    '0%': { transform: 'perspective(400px) scale(0)' },
                                                    '50%': { transform: 'perspective(400px) scale(1.5)' },
                                                    '100%': { transform: 'perspective(400px) scale(1)' },
                                                }
                                            }}
                                        >
                                            🔗
                                        </Typography>
                                    )}
                                </IconButton>
                                <IconButton sx={{ height: '40px', width: '40px' }}
                                    onClick={handleOpenRoute}>
                                    <DirectionsIcon />
                                </IconButton>
                            </Box>
                            <AttendButton
                                meetId={event.id}
                                eventDate={event.recurringDay ? new Date(getNextOccurrence(event.date, event.recurringDay)) : new Date(event.date)}
                                isAttending={isAttending}
                                email={user?.email}
                                loading={loading}
                                isUserVerified={user?.isVerified || false}
                                attendeesCount={attendeesCount}
                                onAttendChange={(isAttending, newAttendeesCount) => {
                                    setAttendeesCount(newAttendeesCount);
                                    setIsAttending(isAttending);
                                }} />
                        </Box>
                        <Typography
                            variant="h6"
                            color="text.secondary"
                            gutterBottom
                        >
                            {event.title || event.customTitle}
                        </Typography>
                        <Box sx={{ marginY: 3 }}>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📅</Typography>
                                <Typography variant="body2" color="text.secondary">{formattedDate}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📍</Typography>
                                <Typography variant="body2" color="text.secondary">{event.location}</Typography>
                            </Box>
                        </Box>

                        {event.seoDescription && (
                            <Box sx={{ marginY: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Beschrijving
                                </Typography>
                                <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontSize="13pt"
                                        sx={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescription ? 'unset' : 3,
                                            WebkitBoxOrient: 'vertical',
                                            transition: 'all 0.3s ease',
                                            position: 'relative',
                                            mb: 1,
                                        }}
                                    >
                                        {event.seoDescription}
                                    </Typography>
                                    {!showFullDescription && event.seoDescription.length > 200 && (
                                        <Box
                                            sx={(theme) => ({
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                height: '100px',
                                                background: `linear-gradient(to bottom, 
                                                    transparent 0%, 
                                                    ${theme.palette.background.paper} 66%
                                                )`,
                                                pointerEvents: 'none',
                                                marginTop: '-100px',
                                            })}
                                        />
                                    )}
                                    {event.seoDescription.length > 200 && (
                                        <Button
                                            onClick={() => setShowFullDescription(!showFullDescription)}
                                            variant='outlined'
                                            size='small'
                                            color='secondary'
                                            sx={{
                                                margin: '0 auto',
                                                textAlign: 'center',
                                                width: '120px',
                                            }}
                                        >
                                            {showFullDescription ? 'Toon minder' : 'Toon meer'}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        )}

                        {allKeywords.length > 0 && (
                            <Box sx={{ marginY: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Tags
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {allKeywords.map((keyword, index) => (
                                        <Chip
                                            key={index}
                                            label={keyword.split(' ').map(word =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            ).join(' ')}
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {meetId && <ReviewSection meetId={meetId} user={user} />}
                    </Box>
                </Box>

                <AuthModal
                    open={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                    message={'Log in om te bewaren'}
                />
            </Box>
        </>
    );
};

export default EventDetails;
